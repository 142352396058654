.MoodEngine {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  transition: background-color 1200ms ease-in-out;

  &:focus {
    outline: 0;
  }

  > .title {
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-size: 50px;
    padding-top: 20px;
    text-align: center;
  }

  > .quote {
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 45px;
    text-align: center;
    transition: opacity 400ms ease-in-out;
    width: calc(100% - 150px);

    @media only screen and (max-width: 500px) {
      font-size: 35px;
    }

    &.transparent {
      opacity: 0;
    }
  }

  .footer {
    align-items: flex-end;
    color: #fff;
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: space-between;
    padding-bottom: 20px;
    width: calc(100% - 40px);

    > div {
      flex: 1;

      &.info {
        font-size: 15px;
        text-align: center;

        @media only screen and (max-width: 1200px) {
          display: none;
        }

        > a {
          color: inherit;
        }
      }
    }
  }
}
